export default {
  name: "CustomersDetailsComponent",
  data() {
    return {
      heightTable: 0,
      bHeightTable: 0,
      tableDataUsers: [],
      iCurrentPage: 1,
      iItemsPerPage: 5,
      iNumPages: 0,
      tabs: 0,
      screenHeight: 0,
      rating: 2.3,
      model: null,
      texts: "",
      customerName: "",
      customerDescription: "",
      customerDetailArr: [],
      wareHouseDetailArr: [],
      documentDetailArr: [],
      isMobile: false,
      isMobileOrIsTablet: false,
      screenWidth: 0,
      bVerified: null,
      bBlockStatus: null,
      bHaveCredit: null,
      sLogoKey: ''

    };
  },
  beforeMount() {
    this.texts = FILE.customerTexts[this.selectLanguage];
    this.$store.commit('setMenuSidebar', {active:false})

  },
  mounted() {
    this.customerDetail();

  },
  created() {
    window.addEventListener("loadstart", this.contentTableHeight)
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("loadstart", this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    toRestrictItemcustomer(item) {

      this.$store.commit("setGlobalDialog", {
        active: true,
        title: this.texts.customerDetails.profileDetail.userDetail.toRestrictTexts.title,
        descipcion: (!item.bVerified) ? this.texts.customerDetails.profileDetail.userDetail.toRestrictTexts.descriptionVerified : this.texts.customerDetails.profileDetail.userDetail.toRestrictTexts.description,
        note: (!item.bVerified) ? this.texts.customerDetails.profileDetail.userDetail.toRestrictTexts.noteVerified : this.texts.customerDetails.profileDetail.userDetail.toRestrictTexts.note,
        chkLabel: (!item.bVerified) ? this.texts.customerDetails.profileDetail.userDetail.toRestrictTexts.chkLabelVerified : this.texts.customerDetails.profileDetail.userDetail.toRestrictTexts.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/customer_enterprises/${item.sCustomerEnterpriseId}/visible/${!item.bVerified}`,
        return: false,
        params: {},
        dobleConfirm: true
      });
    },
    changeEstatusItem(item) {
      this.$store.commit("setGlobalDialog", {
        active: true,
        title: this.texts.customerDetails.profileDetail.userDetail.changeStatusTexts.title,
        descipcion: (!item.bBlockStatus) ? this.texts.customerDetails.profileDetail.userDetail.changeStatusTexts.descriptionUnlock : this.texts.customerDetails.profileDetail.userDetail.changeStatusTexts.description,
        note: (!item.bBlockStatus) ? this.texts.customerDetails.profileDetail.userDetail.changeStatusTexts.noteUnlock : this.texts.customerDetails.profileDetail.userDetail.changeStatusTexts.note,
        chkLabel: (!item.bBlockStatus) ? this.texts.customerDetails.profileDetail.userDetail.changeStatusTexts.chkLabelUnlock : this.texts.customerDetails.profileDetail.userDetail.changeStatusTexts.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${item.sCustomerEnterpriseId}/block`,
        return: false,
        dobleConfirm: true,
        params: { bBlock: !item.bBlockStatus }
      });
    },
    toModifyCreditAuthorization(item) {
      this.$store.commit("setGlobalDialog", {
        active: true,
        title: this.texts.customerDetails.profileDetail.userDetail.modifyCreditAuthorization.title,
        descipcion: this.texts.customerDetails.profileDetail.userDetail.modifyCreditAuthorization.description,
        note:
          (this.bHaveCredit) ?
            this.texts.customerDetails.profileDetail.userDetail.modifyCreditAuthorization.noteRevoke
            :
            this.texts.customerDetails.profileDetail.userDetail.modifyCreditAuthorization.noteAuthorize
        ,
        chkLabel: this.texts.customerDetails.profileDetail.userDetail.modifyCreditAuthorization.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${item.sCustomerEnterpriseId}/credit`,
        return: false,
        params: { bHaveCredit: !this.bHaveCredit },
        dobleConfirm: true
      });
    },
    deleteItemCustomer(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.customerDetails.profileDetail.userDetail.deleteTexts.title,
        descipcion: this.texts.customerDetails.profileDetail.userDetail.deleteTexts.description,
        note: this.texts.customerDetails.profileDetail.userDetail.deleteTexts.note,
        chkLabel: this.texts.customerDetails.profileDetail.userDetail.deleteTexts.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/customer_enterprises/${item.sCustomerEnterpriseId}`,
        return: true,
        dobleConfirm: true
      });
    },
    dialogPasswordChange() {
      this.$store.commit("setPasswordChange", {
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$route.params.id}/password`,
        active: true,
      });
    },
    detailItemUser(item) {
      this.$store.commit("setdialogDetailsGlobalUser", {
        active: true,
        arr: item,
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$route.params.id}/employees/${item.sCustomerId}`,
        apiChangeStatus: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$route.params.id}/employees/${item.sCustomerId}`,

      });
    },
    detailsWarehouse(item) {
      this.$store.commit("setdialogGlobalWarehouse", {
        active: true,
        arr: item,
        apidetail: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$route.params.id}/warehouses/${item.sCustomerWarehouseId}`,
        apidocument: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$route.params.id}/warehouses/${item.sCustomerWarehouseId}/files`
      });
    },
    deleteItemUser(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.customerDetails.usersDetail.deleteTexts.title,
        descipcion: this.texts.customerDetails.usersDetail.deleteTexts.description,
        note: this.texts.customerDetails.usersDetail.deleteTexts.note,
        chkLabel: this.texts.customerDetails.usersDetail.deleteTexts.chkLabel,
        dobleConfirm: true,
        api: `${URI}/api/v1/${this.selectLanguage}/users/${item.sCustomerId}`,
      });
    },
    deleteItemWareHouse(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.customerDetails.warehousesDetail.delete.title,
        descipcion: this.texts.customerDetails.warehousesDetail.delete.description,
        note: this.texts.customerDetails.warehousesDetail.delete.note,
        chkLabel: this.texts.customerDetails.warehousesDetail.delete.chkLabel,
        dobleConfirm: true,
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${item.sCustomerEnterpriseId}/warehouses/${item.sCustomerWarehouseId}`,
      });
    },
    deleteItemDocument(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.customerDetails.documentsDetail.delete.title,
        descipcion: this.texts.customerDetails.documentsDetail.delete.description,
        note: this.texts.customerDetails.documentsDetail.delete.note,
        chkLabel: this.texts.customerDetails.documentsDetail.delete.chkLabel,
        dobleConfirm: false,
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${item.sCustomerEnterpriseId}/files/${item.sCustomerFileTypeId}`,
      });
    },
    contentTableHeight() {
      this.heightTable = (document.getElementsByClassName('tableContent'))
      if (this.heightTable[0] !== undefined) {
        switch (this.tabs) {
          case 0:
            // this.bHeightTable = (this.heightTable[0].clientHeight > 1133 || window.innerHeight < 970)
            this.bHeightTable = true
            break;
          case 1:
            this.bHeightTable = (this.heightTable[0].clientHeight > 1120 || window.innerHeight > 800)
            break;
          case 2:
            this.bHeightTable = (this.heightTable[0].clientHeight > 950 || window.innerHeight > 800)
            break;
          case 3:
            this.bHeightTable = (this.heightTable[0].clientHeight > 550 || window.innerHeight < 800)
            break;

          default:
            break;
        }
      }
    },
    handleResize() {
      this.contentTableHeight()
      this.screenHeight = window.innerHeight - 255;
      this.isMobile = window.innerWidth < 600
      this.isMobileOrIsTablet = window.innerWidth < 960



      // this.screenWidth = window.innerWidth - 150;
      if (window.innerWidth > 900) {
        this.screenWidth = window.innerWidth - 115;
      } else {
        this.screenWidth = window.innerWidth - 10;
      }
    },
    customerDetail() {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$route.params.id}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {
            // iPageNumber: 1,
          },
        }
      )
        .then((response) => {
          this.customerDetailArr = response.data.results;

          this.bHaveCredit = this.customerDetailArr.bHaveCredit;
          this.sLogoKey = response.data.results.oCustomerLogo.sLogoKey
          this.customerName = response.data.results.sName;
          this.bVerified = this.customerDetailArr.bVerified;
          this.bBlockStatus = this.customerDetailArr.bBlockStatus;
          this.contentTableHeight();

        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    userDetail() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$route.params.id}/employees`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,

        }
      })
        .then((response) => {
          this.tableDataUsers = response.data.results.map((e) =>{
            return{
              ...e,
              sAllName: e.sName + ' ' + e.sLastname
            }
          });
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit('refresher', false)
          this.contentTableHeight();


        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    warehousesDetail() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$route.params.id}/warehouses`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
        }
      })
        .then((response) => {
          this.wareHouseDetailArr = response.data.results;
          this.contentTableHeight();
          this.$store.commit('refresher', false)
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    documentDetail() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$route.params.id}/files`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
        }
      })
        .then((response) => {
          this.documentDetailArr = response.data.results;
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.contentTableHeight();

        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },

    downloadFile(fileUrl) {
      window.open(fileUrl, '_blank')
    }

  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    refreshTable: function () {
      this.customerDetail();
      this.userDetail();
      this.warehousesDetail();
      this.documentDetail();
    },
    iCurrentPage: function () {
      switch (this.tabs) {
        case 0:
          // this.customerDetail();
          this.handleResize();
          break;
        case 1:
          this.userDetail();
          break;
        case 2:
          this.warehousesDetail();
          break;
        case 3:
          this.documentDetail();
          break;

        default:
          break;
      }
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.customerTexts[this.selectLanguage];
      }
    },
    tabs: function () {
      switch (this.tabs) {
        case 0:
          // this.customerDetail();
          // this.handleResize();
          break;
        case 1:
          this.userDetail();
          break;
        case 2:
          this.warehousesDetail();
          break;
        case 3:
          this.documentDetail();
          break;

        default:
          break;
      }

    }
  },
};